import isPropValid from '@emotion/is-prop-valid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    alpha,
    Button,
    darken,
    Divider,
    FormControl,
    InputLabel,
    Select,
    Table,
    Tooltip,
    tooltipClasses,
    Typography,
    type ButtonProps,
    type FormControlTypeMap,
    type InputLabelProps,
    type SelectProps,
    type TableProps,
    type TooltipProps,
    type TypographyTypeMap,
} from '@mui/material';
import { type DefaultComponentProps } from '@mui/material/OverridableComponent';
import { css, styled } from '@mui/material/styles';
import { theme } from 'styles/mui-theme';

// manually type `prop: string` due to https://github.com/emotion-js/emotion/issues/2220#issuecomment-764011205
export const TritonButton = styled(Button, { shouldForwardProp: (prop: string) => isPropValid(prop) })<
    ButtonProps & { target?: string }
>(() => ({
    '&.MuiButton-root': {
        background: 'transparent',
        border: `1px solid ${theme.tritonColors.darkBlue}`,
        color: theme.tritonColors.darkBlue,
        borderRadius: 0,
        fontWeight: 'normal',
        padding: '16px 26px',
        fontSize: 17,
        '&:hover': {
            background: theme.tritonColors.darkBlue,
            color: 'white',
        },
        '& .MuiTypography-root': {
            fontSize: 'inherit',
        },
    },
}));

export const TritonButtonFilled = styled(TritonButton)(() => ({
    '&.MuiButton-root': {
        background: theme.tritonColors.darkBlue,
        color: 'white',
        '&:hover': {
            background: theme.tritonColors.skyBlue,
            borderColor: theme.tritonColors.skyBlue,
            color: 'white',
        },
    },
}));

export const TritonTable = styled(Table)<TableProps>(() => ({
    '&.MuiTable-root': {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            borderRight: '2px solid white',
            padding: '13px 10px',
            '&:last-of-type': {
                borderRight: 'none',
            },
        },
        '& .MuiTableHead-root': {
            '& .MuiTableCell-root': {
                background: theme.tritonColors.darkBlue,
                color: 'white',
                fontWeight: 500,
                '& .MuiTableSortLabel-icon': {
                    color: 'currentColor',
                },
                '& .MuiTableSortLabel-root': {
                    color: 'currentColor',
                },
            },
        },
    },
}));

export const tritonTableRecordRow = css({
    background: '#ffffff',
    '&:hover': {
        background: darken('#ffffff', 0.05),
        cursor: 'pointer',
    },
});

export const tritonTableRecordRowAlternate = css([
    tritonTableRecordRow,
    {
        background: theme.tritonColors.veryLightBlue,
        '&:hover': {
            background: darken(theme.tritonColors.veryLightBlue, 0.05),
        },
    },
]);

export const ReportFormControl = ({
    selected,
    ...props
}: DefaultComponentProps<FormControlTypeMap<{ selected?: boolean }, 'div'>>) => (
    <FormControl
        variant="filled"
        css={[
            {
                '& .MuiFormLabel-root': {
                    transition: 'none',
                    textTransform: 'uppercase',
                    '&, &.Mui-focused': {
                        color: theme.palette.text.primary,
                        opacity: 0.5,
                    },
                },
                '& .MuiSelect-icon': {
                    color: 'currentcolor',
                },
            },
            selected && {
                '& .MuiFormLabel-root': {
                    '&, &.Mui-focused': {
                        color: 'white',
                    },
                },
                // use div to make sure we have high specificity than ReportSelect's .MuiFilledInput-root style
                '& div.MuiFilledInput-root': {
                    background: theme.tritonColors.skyBlue,
                    color: 'white',
                },
            },
        ]}
        {...props}
    />
);

export const ReportDownloadButton = styled(Button, { shouldForwardProp: (prop: string) => isPropValid(prop) })<
    ButtonProps & { target?: string }
>(() => ({
    '&.MuiButton-root': {
        background: theme.tritonColors.darkBlue,
        color: 'white',
        border: `1px solid ${theme.tritonColors.darkBlue}`,
        borderRadius: 0,
        fontWeight: 'normal',
        padding: '0px 26px',
        '&:hover': {
            background: theme.tritonColors.skyBlue,
            borderColor: theme.tritonColors.skyBlue,
            color: 'white',
        },
        '& .MuiTypography-root': {
            fontSize: 'inherit',
        },
    },
}));

export function ReportInputLabel(props: InputLabelProps) {
    return <InputLabel sx={{ pointerEvents: 'none' }} {...props} />;
}

export function ReportSelect<T>(props: SelectProps<T>) {
    return (
        <Select
            css={{
                '&.MuiFilledInput-root': {
                    background: theme.palette.background.paper,
                    borderRadius: 0,
                    color: theme.palette.text.primary,
                    border: `1px solid ${theme.palette.primary.main}`,
                    height: 62,
                    '&:before, &:after': {
                        display: 'none',
                    },
                    '&.MuiInputBase-adornedEnd': {
                        paddingRight: 0, // disable padding right since the custom clear adornment is absolute positioned
                    },
                    '& .MuiInputBase-input': {
                        paddingTop: 27,
                        paddingBottom: 12,
                    },
                },
            }}
            MenuProps={{
                sx: {
                    '& .MuiPaper-root': {
                        borderRadius: 0,
                        '& .MuiMenu-list': {
                            '& .MuiMenuItem-root': {
                                '&.Mui-selected': {
                                    backgroundColor: alpha(theme.tritonColors.paleBlue, 0.3),
                                    '& .MuiSvgIcon-root': {
                                        color: theme.tritonColors.darkBlue,
                                    },
                                },
                            },
                        },
                    },
                },
            }}
            IconComponent={KeyboardArrowDownIcon}
            variant="filled"
            {...props}
        />
    );
}

export function ReportSelectSecondary<T>(props: SelectProps<T>) {
    return (
        <Select
            css={{
                '&.MuiFilledInput-root': {
                    background: theme.palette.grey[200],
                    borderRadius: 0,
                    color: theme.palette.text.primary,
                    height: 'auto',
                    '&:before, &:after': {
                        display: 'none',
                    },
                    '&.MuiInputBase-adornedEnd': {
                        paddingRight: 0, // disable padding right since the custom clear adornment is absolute positioned
                    },
                    '& .MuiInputBase-input': {
                        '&.MuiInputBase-inputAdornedEnd': {
                            paddingRight: 52,
                        },
                    },
                },
            }}
            MenuProps={{
                sx: {
                    '& .MuiPaper-root': {
                        borderRadius: 0,
                        '& .MuiMenu-list': {
                            '& .MuiMenuItem-root': {
                                '&.Mui-selected': {
                                    backgroundColor: alpha(theme.tritonColors.paleBlue, 0.3),
                                    '& .MuiSvgIcon-root': {
                                        color: theme.tritonColors.darkBlue,
                                    },
                                },
                            },
                        },
                    },
                },
            }}
            IconComponent={KeyboardArrowDownIcon}
            variant="filled"
            {...props}
        />
    );
}

export function ReportSelectOutlined<T>(props: SelectProps<T>) {
    return (
        <ReportSelectSecondary
            css={{
                '&.MuiFilledInput-root': {
                    height: 'auto',
                    background: 'transparent',
                    border: `1px solid ${theme.palette.grey[400]}`,
                    color: theme.tritonColors.darkBlue,
                    '& .MuiSelect-select': {
                        paddingTop: 8,
                    },
                },
            }}
            {...props}
        />
    );
}

export const HeadingTwo = (props: DefaultComponentProps<TypographyTypeMap>) => (
    <Typography
        {...props}
        variant="h2"
        sx={{
            fontSize: 44,
            color: theme.tritonColors.darkBlue,
            [theme.breakpoints.down('md')]: {
                fontSize: 36,
            },
        }}
    />
);

export const HeadingThree = (props: DefaultComponentProps<TypographyTypeMap>) => (
    <Typography
        {...props}
        variant="h3"
        sx={{
            color: theme.tritonColors.darkBlue,
            fontWeight: 'normal',
            fontSize: '1.5em',
            lineHeight: 1.4,
        }}
    />
);

export const TritonDivider = styled(Divider)(() => ({
    borderColor: theme.tritonColors.paleBlue,
}));

export const TritonTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} enterTouchDelay={200} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.tritonColors.darkBlue,
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.tritonColors.darkBlue,
    },
}));

/**
 * generate rules for a simple flex wrap grid.
 * uses negative margins on the parent to ensure the last row of children's
 * margins don't affect the total height of the parent container.
 *
 * const flexWrappedGrid = makeFlexWrappedGrid(11, 6);
 *
 * <div css={flexWrappedGrid.parentCss}>
 *   <div css={flexWrappedGrid.childCss}>Child</div>
 *   <div css={flexWrappedGrid.childCss}>Child</div>
 * </div>
 *
 */
export function makeFlexWrappedGrid(gapHorizontal: number | string, gapVertical: number | string) {
    const parentCss = css({
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: valueToNegative(gapVertical),
        '[dir=ltr] &': {
            marginRight: valueToNegative(gapHorizontal),
        },
        '[dir=rtl] &': {
            marginLeft: valueToNegative(gapHorizontal),
        },
    });

    const childCss = css({
        paddingBottom: gapVertical,
        '[dir=ltr] &': {
            paddingRight: gapHorizontal,
        },
        '[dir=rtl] &': {
            paddingLeft: gapHorizontal,
        },
    });

    return {
        parentCss,
        childCss,
    };
}

function valueToNegative(value: number | string): string | number {
    return typeof value === 'string' ? '-' + value : -value;
}

export const buttonReset = css({
    appearance: 'none',
    background: 'transparent',
    border: 0,
    color: 'inherit',
    font: 'inherit',
    letterSpacing: 'inherit',
    textRendering: 'inherit',
    padding: 0,
    '&:not(:disabled, .disabled)': {
        cursor: 'pointer',
    },
});
